<template>
  <div class="car-item">
    <nuxt-link
        class="car-item__slider"
        :to="anonymous ? '' : `/item-${item.id}`"
    >
      <swiper
          v-if="previews?.length"
          :modules="[Pagination, EffectFade]"
          pagination
          :lazy="true"
          effect="fade"
          :fade-effect="{crossFade: true}"
          @swiper="onSwiper"
      >
        <swiper-slide v-for="(car, index) in previews" :key="index">
          <img :src="car.imagesMiddle[0]" alt="" loading="lazy" />
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </swiper-slide>
      </swiper>

      <div
          class="car-item__time"
          v-if="today < start"
      >До начала: <b class="text-nowrap">{{remainingTime(start.diff(today, 'seconds'))}}</b>
      </div>
      <div
          class="car-item__time --started"
          v-else-if="(today >= start) && (today < end) && (lotStatus === 'active')"
      >До окончания: <b class="text-nowrap">{{remainingTime(end.diff(today, 'seconds'))}}</b>
      </div>
      <div
          class="car-item__time --finished"
          v-else-if="(today >= end) && (lotStatus !== 'active')"
      >
        <template v-if="item.dealStatuses?.bid_confirmed?.isActive">Ставка принята</template>
        <template v-else>
          Завершен: <span class="text-nowrap">{{end.format('DD.MM.YYYY в HH:mm')}}</span>
        </template>
      </div>

      <div class="car-item__sliderHovers">
        <div
            class="car-item__sliderHoversArea"
            @mouseover="carSwiper.slideTo(index)"
            @mouseout="carSwiper.slideTo(0)"
            v-for="(car, index) in previews" :key="index"
        ></div>
      </div>
    </nuxt-link>
    <div class="col-12 col-lg">
      <div class="row gx-0 h-100">
        <div class="col-12 col-xxl-6">
          <div class="car-item__data">
            <div class="car-item__dataRow">
              <div class="city">{{ item.city?.name }}</div>
              <nuxt-link
                  :to="anonymous ? '' : `/item-${item.id}`"
              >
                <div class="number">Лот №:&nbsp; {{item.contractNumber}}</div>
              </nuxt-link>
            </div>
            <div class="car-item__dataRow">
              <button
                  v-if="!anonymous"
                  class="icon-favorite"
                  type="button"
                  :class="{'active': favorite}"
                  @click="favorite = !favorite; toggleFavorite(item.id, favorite)"
              ></button>
              <nuxt-link
                  :to="anonymous ? '' : `/item-${item.id}`"
                  class="name"
                  :class="{'--broken': !item.isFixed}"
              >
                {{item.car.name}}
              </nuxt-link>
            </div>
            <div class="car-item__dataRow --characteristics">
              <span class="text-nowrap">{{item.car.carYear}} г.</span>
              <span class="divider">/</span>
              <span class="text-nowrap">{{formatNumber(item.car.mileage)}} км</span>
              <span class="divider">/</span>
              <template v-if="!anonymous">
                <span class="text-nowrap">{{item.car.plateNumber}}</span>
                <span class="divider">/</span>
                <span class="text-nowrap"><b>VIN:&nbsp;</b>{{item.car.bodyNumber}}</span>
                <span class="divider">/</span>
              </template>
              <span v-if="item.car?.horsePower" class="text-nowrap">{{item.car?.horsePower}} л.с.</span>
            </div>
            <div class="car-item__dataInfo" v-if="item.commonComments">
              <span>Дополнительная информация:</span>
              {{item.commonComments}}
            </div>
          </div>
        </div>
        <div class="col-12 col-xxl-6" v-if="!anonymous">
          <div class="car-item__trade justify-content-end">
            <div class="car-item__tradeSettings">
              <div
                  class="row justify-content-end justify-content-sm-between align-items-center"
                  v-if="(today >= start) && (today < end)"
              >
                <div class="col-6 col-sm">
                  <template v-if="item.bid?.userBid !== null">
                    <div class="car-item__betStatus --leader" v-if="item.bid?.userBid?.userIsLeader === true">
                      <span></span>
                      <b>Вы лидируете</b>
                    </div>
                    <div class="car-item__betStatus" v-else-if="item.bid?.userBid?.userIsLeader === false">
                      <span></span>
                      <b>Нужно поднять ставку</b>
                    </div>
                  </template>
                </div>
                <div class="col-6 col-sm-auto">
                  <div class="car-item__autoBet">
                    <ToggleSwitch :modelValue="autoBet" @change="onAutoBetClick" />
                    <label @click="onAutoBetClick">Автоставка</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="car-item__tradeForm" :class="{'--auto': autoBet}">
              <FormLoading v-if="item.isActive && priceLoading" />
              <div v-if="isLotNotFound" class="car-item__tradeForm__wait">
                Лот не найден
              </div>
              <div v-else-if="today < start" class="car-item__tradeForm__wait">
                Торги еще не начались
              </div>
              <template v-else-if="(today >= start) && (today < end)">
                <div class="row justify-content-between align-items-end">
                  <div class="col-12 col-sm-auto car-item__tradeBet --bet">
                    <span>Текущая ставка:</span><b>{{formatNumber(item.betData.current)}} &#x20bd;</b>
                  </div>
                  <div class="col-12 col-sm-auto car-item__tradeBet --fee" v-if="false">
                    <span>Комиссия:</span><b>{{formatNumber(item.betData.current * item.betData.fee)}} &#x20bd; ({{item.betData.fee * 100}}%)</b>
                  </div>
                </div>
                <form class="car-item__tradeFormControls" @submit.prevent="bidHandler">
                    <span class="car-item__tradeFormAttention" v-if="errors?.errors?.price?.length">
                        <template v-for="message in errors.errors.price">
                          {{ message }}<br>
                        </template>
                    </span>
                  <span class="car-item__tradeFormAttention" v-else-if="errors?.errors?.limit?.length">
                        <template v-for="message in errors.errors.limit">
                          {{ message }}<br>
                        </template>
                    </span>
                  <!--                  <span class="car-item__tradeFormInfo" v-else>-->
                  <!--                      Минимальная ставка: {{formatNumber(item.betData.current + item.betData.step)}} &#x20bd;-->
                  <!--                    </span>-->
                  <div class="row gx-0 align-items-end">
                    <div class="col-12 col-sm">
                      <InputNumber
                          class="js-carItem w-100"
                          :class="isAutoBetSet ? 'autobet-placeholder' : ''"
                          :placeholder="betPlaceholder"
                          v-model="betValue"
                          inputId="betVal"
                          format
                          fluid
                          locale="ru"
                          :useGrouping="true"
                          @input="betValue = $event.value"
                          @focus="focusHandler"
                      />
                    </div>
                    <div class="col-12 col-sm-auto">
                      <template v-if="!isAutoBetSet">
                        <button
                            v-if="!autoBet"
                            type="submit"
                            class="button button--dark"
                            :disabled="!betValue || betValue < item.bid?.minPriceForAddBid || isBetting"
                        >
                          <template v-if="item.bid?.userBid !== null">
                            Сделать ставку
                          </template>
                          <template v-else>Подтвердить</template>
                        </button>
                        <button
                            v-else
                            type="submit"
                            class="button button--dark"
                            :disabled="!betValue || betValue < item.bid?.minLimitForAddAutoBid || isBetting"
                        >
                          <template v-if="item.bid?.userBid !== null">
                            Задать лимит
                          </template>
                          <template v-else>Подтвердить</template>
                        </button>
                      </template>

                      <button
                          v-else
                          type="submit"
                          class="button button--dark"
                          :disabled="!betValue || betValue < item.bid?.minLimitForAddAutoBid || isBetting"
                      >
                        Изменить лимит
                      </button>
                    </div>
                  </div>
                </form>
              </template>
              <template v-else-if="today >= end">
                <div class="row justify-content-between align-items-end">
                  <div class="col-12 col-sm-auto car-item__tradeBet --bet">
                    <span>Текущая ставка:</span><b>{{formatNumber(item.betData.current)}} &#x20bd;</b>
                  </div>
                  <div class="col-12 col-sm-auto car-item__tradeBet --fee" v-if="false">
                    <span>Комиссия:</span><b>{{formatNumber(item.betData.current * item.betData.fee)}} &#x20bd; ({{item.betData.fee * 100}}%)</b>
                  </div>
                </div>

                <!-- статусы готовности: -->
                <div class="car-item__tradeForm__wait" v-if="lotStatus === 'active'">
                  <FormLoading />
                </div>

                <div class="car-item__tradeForm__wait" v-else-if="item.currentStatus === 'finished_archive' && item.statusBid.failStatus === null">
                  <span class="text-left">Торги завершены.</span>
                </div>

                <div class="car-item__tradeForm__wait" v-else-if="item.statusBid.failStatus !== null">
                  <div class="car-item__tradeForm__fail text-left" v-if="item.statusBid.failStatus === 'bid_not_leader'">
                    <span>Ставка не сыграла</span><br>
                    Ваша последняя ставка: <b>{{formatNumber(item.statusBid.userPrice)}} руб.</b><br>
                    Выигравшая ставка: <b>{{formatNumber(item.betData.current)}} руб.</b>
                  </div>
                  <div class="car-item__tradeForm__fail text-left" v-if="item.statusBid.failStatus === 'bid_not_confirmed'">
                    <span>Ставка отклонена</span><br>
                    Ваша ставка: <b>{{formatNumber(item.statusBid.userPrice)}} руб.</b><br>
                    Продавец решил не принимать ставку
                  </div>
                  <div class="car-item__tradeForm__fail text-left" v-if="item.statusBid.failStatus === 'deal_finished_failed'">
                    <span>Срыв сделки</span>
                  </div>
                </div>
                <template v-else>
                  <div
                      class="car-item__tradeForm__wait"
                      v-if="item.currentStatus === 'waiting'"
                  >
                    Продавец принимает решение о&nbsp;принятии&nbsp;ставки.
                  </div>
                  <div
                      class="car-item__tradeForm__wait"
                      v-else-if="item.dealStatuses?.bid_confirmed.isActive &&
                          !item.dealStatuses?.deal_preparation_payment_documents.isActive">
                    Ставка подтверждена.
                  </div>

                  <template v-if="item.currentStatus === 'processing'">
                    <div
                        class="car-item__tradeForm__wait"
                        v-if="item.dealStatuses?.deal_preparation_payment_documents.isActive &&
                                !item.dealStatuses?.deal_payment_waiting.isActive"
                    >
                      <img :src="iconDoc" />
                      <span class="text-left">Подготовка документов.</span>
                    </div>

                    <div class="car-item__tradeForm__status"
                         v-if="item.dealStatuses?.deal_payment_waiting.isActive &&
                                !item.dealStatuses?.deal_paid_with_preparing_closing_documents.isActive"
                    >
                      <template v-if="archive !== null">
                        <div class="car-item__tradeForm__wait">
                          <ul class="car-item__tradeForm__downloads">
                            <li v-if="item.documents.dkp !== null">
                              <a download :href="item.documents.dkp.path" target="_blank">
                                {{Documents.dkp}}.{{item.documents.dkp.ext}}
                              </a>
                            </li>
                            <li v-if="item.documents.invoiceForPayment !== null">
                              <a download :href="item.documents.invoiceForPayment.path" target="_blank">
                                {{Documents.invoiceForPayment}}.{{item.documents.invoiceForPayment.ext}}
                              </a>
                            </li>
                            <li v-if="item.documents.pts !== null">
                              <a download :href="item.documents.pts.path" target="_blank">
                                {{Documents.pts}}.{{item.documents.pts.ext}}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="car-item__tradeForm__wait">
                          <a v-if="archive !== null" :href="archive.path" download target="_blank">
                            <img :src="iconDownload" class="mx-0" /><br>
                            Скачать все одним архивом
                          </a>
                        </div>
                      </template>
                      <div class="car-item__tradeForm__wait" v-else>
                        Скоро с вами свяжутся о вопросам оформления документов
                      </div>
                    </div>

                    <div class="car-item__tradeForm__wait"
                         v-if="item.dealStatuses?.deal_paid_with_preparing_closing_documents.isActive &&
                                !item.dealStatuses?.deal_documents_sent.isActive"
                    >
                      <img :src="iconCard" />
                      <img :src="iconDoc" />
                      <span class="text-left">
                          Оплата получена.
                          <br>Подготовка закрывающих документов
                        </span>
                    </div>

                    <div class="car-item__tradeForm__wait"
                         v-if="item.dealStatuses?.deal_documents_sent.isActive &&
                                !item.dealStatuses?.deal_ready_for_delivery.isActive"
                    >
                      <img :src="iconSent" />
                      <span class="text-left">Документы отправлены на стоянку/продавцу.</span>
                    </div>

                    <div class="car-item__tradeForm__status"
                         v-if="item.dealStatuses?.deal_ready_for_delivery.isActive &&
                                !item.dealStatuses?.deal_finished_success.isActive"
                    >
                      <div class="car-item__tradeForm__wait">
                          <span class="text-nowrap">
                            <img :src="iconReady" class="mx-0" /><br>
                            Готов к выдаче
                          </span>
                      </div>
                      <div class="car-item__tradeForm__wait">
                        <div class="text-left">
                          <div class="attention">Место выдачи:</div>
                          <span>
                              {{item.car.parking?.fullAddress}}
                              <br>
                              <template v-if="item.car.parking?.contacts !== null" v-html="item.car.parking.contacts[0]"></template>
                            </span>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="car-item__tradeForm__status"
                       v-if="item.dealStatuses?.deal_finished_success.isActive"
                  >
                    <div class="car-item__tradeForm__wait">
                          <span class="text-nowrap">
                            <img :src="iconReadyDone" class="mx-0" /><br>
                            Выдан. <br>Сделка завершена
                          </span>
                    </div>
                    <div class="car-item__tradeForm__wait --disabled">
                      <div class="text-left">
                        <div class="attention">Место выдачи:</div>
                        <span>
                          {{item.car.parking?.fullAddress}}
                          <br>
                          <template v-if="item.car.parking?.contacts !== null" v-html="item.car.parking.contacts[0]"></template>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- /// -->

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12"
         v-if="today >= end && !anonymous &&
                item.currentStatus !== 'finished_archive' &&
                item.currentStatus !== 'waiting' &&
                (item.currentStatus === 'processing' &&
                item.dealStatuses?.bid_confirmed.isActive ||
                item.dealStatuses?.deal_finished_success.isActive)"
    >
      <trade-timeline :events="events" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, onMounted, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import {EffectFade, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';
import ToggleSwitch from 'primevue/toggleswitch';
import InputNumber from 'primevue/inputnumber';
import type { InputNumberInputEvent } from 'primevue/inputnumber';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import TradeTimeline from "~/components/layout/TradeTimeline.vue";
import FormLoading from "~/components/page/formLoading.vue";

import iconDoc from "~/components/layout/img/trades/icon-doc.svg";
import iconCard from "~/components/layout/img/trades/icon-card.svg";
import iconSent from "~/components/layout/img/trades/icon-sent.svg";
import iconDownload from "~/components/layout/img/trades/icon-download.svg";
import iconReady from "~/components/layout/img/trades/icon-ready.svg";
import iconReadyDone from "~/components/layout/img/trades/icon-ready--done.svg";
import {ApiRequest} from "~/utils/api-request";
import {toggleFavorite} from "~/utils/favorites";
import {echoEvents} from "~/utils/echo";
import {getUserBids, setAutoBetOff, setAutoBetOn} from "~/utils/api/user-bids";

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  anonymous: {
    type: Boolean,
    default: false,
  }
});

const { item } = toRefs(props);

let lotStatus = ref(item.value.currentStatus);

let autoBet = ref(false);
let priceLoading = ref(false);
let favorite = ref(item.value.isFavorite);
let betValue = ref();
const isBetting = ref(false);
const isAutoBetSet = ref(false);
let archive = ref(null);
const isLotNotFound = ref(false);
if (item.value.documents?.archiveDeal !== null) {
  archive.value = item.value.documents.archiveDeal;
} else if (item.value.documents?.archive !== null) {
  archive.value = item.value.documents.archive;
}

watch(item, () => {
  favorite.value = item.value.isFavorite;
}, {
  deep: true,
})

enum Documents {
  dkp = 'Договор купли-продажи',
  invoiceForPayment = 'Счет на оплату',
  pts = 'Паспорт технического средства',
  serviceHistory = 'История обслуживания',
}

let today = ref(moment());
let start = ref(moment(item.value.startAt));
let end = ref(moment(item.value.endAt));
let timerInterval = null;
const updateCurrentTime = () => {
  today.value = moment();
};
if (today.value < end.value){
  timerInterval = setInterval(updateCurrentTime, 1000);
}
// console.log(item.value.car.data?.photos);
let previews = item.value.car.data?.photos?.slice(0, 3);

let download = ref(false);

let errors = ref({
  message: null!,
  errors: {

  }
});

const events = ref([
  {
    date: moment(item.value.dealStatuses?.bid_confirmed?.setAt).format('DD.MM.YYYY'),
    status: 'Ставка <br>подтверждена',
    active: item.value.dealStatuses?.bid_confirmed?.isActive,
  },
  {
    date: moment(item.value.dealStatuses?.deal_preparation_payment_documents?.setAt).format('DD.MM.YYYY'),
    status: 'Подготовка <br>документов',
    active: item.value.dealStatuses?.deal_preparation_payment_documents?.isActive,
  },
  {
    date: moment(item.value.dealStatuses?.deal_payment_waiting?.setAt).format('DD.MM.YYYY'),
    status: item.value.dealStatuses?.deal_paid_with_preparing_closing_documents?.isActive ? 'Оплата <br>получена' : 'Ожидает <br>оплаты',
    active: item.value.dealStatuses?.deal_payment_waiting?.isActive,
  },
  {
    date: moment(item.value.dealStatuses?.deal_paid_with_preparing_closing_documents?.setAt).format('DD.MM.YYYY'),
    status: 'Подготовка <br>закрывающих',
    active: item.value.dealStatuses?.deal_paid_with_preparing_closing_documents?.isActive,
  },
  {
    date: moment(item.value.dealStatuses?.deal_documents_sent?.setAt).format('DD.MM.YYYY'),
    status: 'Документы <br>отправлены',
    active: item.value.dealStatuses?.deal_documents_sent?.isActive,
  },
  {
    date: moment(item.value.dealStatuses?.deal_ready_for_delivery?.setAt).format('DD.MM.YYYY'),
    status: 'Готов <br>к выдаче',
    active: item.value.dealStatuses?.deal_ready_for_delivery?.isActive,
  },
  {
    date: moment(item.value.dealStatuses?.deal_finished_success?.setAt).format('DD.MM.YYYY'),
    status: 'Выдан',
    active: item.value.dealStatuses?.deal_finished_success?.isActive,
  },
]);

const carSwiper = ref(null);

const betPlaceholder = computed(() => {
  let placeholder = `Введите сумму ${autoBet.value ? 'авто' : ''}ставки`;

  const currentLimit = formatNumber(item.value.bid?.minPriceForAddBid || 0);
  const currentAutoBetLimitValue = item.value.bid?.userBid?.userAutoBidLimit || item.value.bid?.currentLimit;

  const autoBetPlaceholder = `Текущий лимит: ${formatNumber(currentAutoBetLimitValue)} Р`;
  const isAutoBetLimitReached = item.value.bid?.userBid?.userAutoBidIsLimited || false;

  if (isAutoBetSet.value) {
    if (isAutoBetLimitReached) {
      placeholder = 'Достигнут лимит автоставки';
    } else {
      placeholder = autoBetPlaceholder;
    }
  } else if (autoBet.value) {
    placeholder = autoBetPlaceholder;
  } else {
    placeholder = 'Минимальная ставка: ' + currentLimit + ' Р';
  }

  return placeholder;
});

const onSwiper = (swiper) => {
  carSwiper.value = swiper;
}

const remainingTime = (seconds) => {
  let d = Math.floor(seconds / (3600*24));
  let h = Math.floor(seconds % (3600*24) / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  let s = Math.floor(seconds % 60);
  if (d > 0){
    return d + 'д. ' + h + 'ч.';
  } else if (h > 0){
    return h + 'ч. ' + m + 'м.'
  } else if (m > 0) {
    return m + 'м. ' + s + 'с.';
  } else {
    return s + 'с.';
  }
}

const formatNumber = (num: number | undefined) => {
  if (!num) return 0;
  return num.toLocaleString('ru');
}
const filterInt = () => {
  if (!/^\d*[1-9]\d*$/.test(betValue.value)) {
    betValue.value = betValue.value.slice(0, -1);
  }
}

const getTradeBid = async(lotId) => {

  getUserBids([lotId]).then(data => {
    item.value.bid = data.lots[lotId];

    item.value.betData.current = item.value.bid.currentPrice;
    item.value.betData.step = item.value.bid.biddingStep;
    // item.value.betData = {
    //   current: item.value.bid.currentPrice,
    //   step: item.value.bid.biddingStep,
    //   fee: 0,
    // }

    priceLoading.value = false;
  })

}

const addBid = async() => {
  priceLoading.value = true;

  let apiRequest = new ApiRequest('post', 'lots/bids/'+ item.value.id +'/add', null, {
    price: betValue.value,
  });

  apiRequest.onSuccess((data) => {
    // console.log(data);
    // betValue.value = '';
    errors.value.errors = {};
    betValue.value = undefined;
    priceLoading.value = false;
  });

  apiRequest.onError((error) => {
    // errors
    errors.value = error;
    priceLoading.value = false;
  });

  apiRequest.onNotFoundError((error) => {
    // errors
    errors.value = error;
    priceLoading.value = false;
    isLotNotFound.value = true;
  });

  await apiRequest.request();
  // console.log(data);
}

async function addAutoBet () {
  await setAutoBetOn({
    id: item.value.id,
    priceLoading,
    betValue,
    autoBet,
    errors,
    isAutoBetSet,
    isLotNotFound
  });
}

async function removeAutoBet () {
  await setAutoBetOff({
    id: item.value.id,
    errors,
    autoBet,
    isAutoBetSet
  });
}


async function bidHandler () {
  isBetting.value = true;
  if (autoBet.value) {
    await addAutoBet();
  } else {
    await addBid();
  }
  isBetting.value = false;
}

const updateDataAfterClose = async () => {
  let apiRequest = new ApiRequest('get', `lots/get/${item.value.id}/for-my`);

  apiRequest.onSuccess((data) => {
    item.value.betData.current = data.lot.currentPrice;
    item.value.currentStatus = data.lot.currentStatus;
    item.value.statusBid.userPrice = data.lot.statusBid?.userPrice;
    item.value.statusBid.failStatus = data.lot.statusBid?.failStatus;
  });

  apiRequest.onError((error) => {
    // errors
  });

  await apiRequest.request();
}

onMounted(() => {

  // console.log(props.item);

  if (!props.anonymous && item.value.isActive){

    echoEvents["bid-added-event"][item.value.id] = function(message){
      if (message.lotId == item.value.id) {
        item.value.bid = message;

        item.value.betData.current = item.value.bid.currentPrice;
        item.value.betData.step = item.value.bid.biddingStep;
        // item.value.betData = {
        //   current: item.value.bid.currentPrice,
        //   step: item.value.bid.biddingStep,
        //   fee: 0,
        // }

        priceLoading.value = false;

        // getTradeBid(item.value.id);
      }
    };

    echoEvents["lot-extended-event"][item.value.id] = function(message){
      if (message.lotId == item.value.id) {
        lotExtendHandler(message);
      }
    };

    echoEvents["lot-update-status-event"][item.value.id] = function(message){
      const currentStatus = message.lots[item.value.id]?.status;
      if (currentStatus) {
        if (currentStatus == 'waiting') {
          updateDataAfterClose();
        }

        lotStatus.value = currentStatus;
      }
    };
  }

  if (props.anonymous && item.value.isActive) {
    echoEvents["lot-update-status-event-anonymous"][item.value.id] = function(message){
      const currentStatus = message.lots[item.value.id]?.status;
      if (currentStatus) {
        lotStatus.value = currentStatus;
      }
    };

    echoEvents["lot-extended-event-anonymous"][item.value.id] = function(message){
      if (message.lotId == item.value.id) {
        lotExtendHandler(message);
      }
    };
  }
});

onBeforeUnmount(() => {
  if (timerInterval) clearInterval(timerInterval);
});

watch(() => item.value, (newValue) => {
  const autoBetValue = newValue?.bid?.userBid?.userIsAutoBid;
  if (autoBetValue !== undefined) {
    autoBet.value = autoBetValue;
    isAutoBetSet.value = item.value.bid?.userBid?.userIsAutoBid || false;
  }
}, {
  deep: true,
  immediate: true
})

function onAutoBetClick() {
  betValue.value = undefined;
  autoBet.value = !autoBet.value;
  if (!autoBet.value && isAutoBetSet.value) {
    removeAutoBet();
  }
}

async function lotExtendHandler (message: {lotId: number, endAt: string}) {
  const {endAt} = message;
  end.value = moment(endAt);
}

function focusHandler() {
  if (autoBet.value) {
    betValue.value = betValue.value ? betValue.value : item.value.bid?.minLimitForAddAutoBid;
  } else {
    betValue.value = betValue.value ? betValue.value : item.value.bid?.minPriceForAddBid;
  }
}

</script>

<style>
.car-item__tradeFormControls .js-carItem .p-inputtext{
  padding: 8px 15px 7px !important;
}
.car-item__slider .swiper{
  height: 100%;
  width: 100%;
}
.autobet-placeholder > .p-inputtext::placeholder {
  color: #FC6337;
}
@media(min-width: 1200px) and (max-width: 1399px){
  .car-item__slider .swiper{
    /*height: auto;*/
  }
}
@media(max-width: 991px){
  .car-item__slider .swiper{
    position: absolute;
    left: 0;
    top: 0;
  }
  .car-item__slider .swiper-pagination{
    bottom: 35px !important;
  }
}
</style>
<style scoped>
.car-item{
  position: relative;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
  /*flex: 0 0 100%;*/
  flex: 1 0 0;
}
.car-item__slider{
  width: 308px;
  position: relative;
  font-size: 0;
  overflow: hidden;
  display: block;
  border-radius: 15px 0 0 15px;
}
.car-item__slider img{
  display: block;
  min-height: 177px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.car-item__sliderHovers{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  z-index: 2;
}
.car-item__sliderHoversArea{
  flex: 1 0 0;
}

.car-item__trade{
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 auto;
  padding-left: 0.8rem;
}
.car-item__tradeForm{
  background: #F4F4F4;
  border-radius: 15px 0;
  padding: 16px;
  border: 1px solid #F4F4F4;
  transition: border 0.3s ease;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.car-item__tradeForm.--auto{
  border-top-color: #FC6337;
  border-left-color: #FC6337;
}
.car-item__tradeSettings{
  padding-right: 0.8rem;
}
.car-item__tradeSettings > .row{
  margin-top: 16px;
  margin-bottom: 16px;
}
.car-item__autoBet{
  display: flex;
  align-items: center;
  justify-content: end;
}
.car-item__autoBet label{
  padding-left: 0.8rem;
  font-size: 14px;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.car-item__autoBet .p-toggleswitch-checked + label{
  opacity: 1;
}
.car-item__betStatus{
  color: #8C8C8C;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.car-item__betStatus span{
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 50%;
  background: #C8C8C8;
  flex: 0 0 20px;
}
.car-item__betStatus.--leader span{
  background: #14BEAA;
}
.car-item__betStatus b{
  flex: 1 0 0;
}

.car-item__tradeBet{
  display: flex;
  align-items: center;
  color: #8C8C8C;
  font-size: 12px;
  margin-bottom: 0.8rem;
}
.car-item__tradeBet.--bet b{
  margin-left: 0.8rem;
  font-size: 20px;
  color: #4B4B4B;
}
.car-item__tradeBet.--fee b{
  font-size: 14px;
  margin-left: 8px;
}

.car-item__tradeFormControls{
  padding-top: 10px;
  position: relative;
}
.car-item__tradeFormControls .input{
  padding: 8px 15px 7px;
}
.car-item__tradeFormControls .button{
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 167px;
}
.car-item__tradeFormAttention,
.car-item__tradeFormInfo{
  display: block;
  line-height: 1.5;
  position: absolute;
  left: 0;
  bottom: 100%;
  margin-bottom: -8px;
}
.car-item__tradeFormAttention{
  font-size: 8px;
  color: #FF6565;
  padding-left: 26px;
  background: url('~/components/layout/img/trades/icon-attention.svg') left center / 18px 18px no-repeat;
}
.car-item__tradeFormInfo{
  font-size: 12px;
  color: #8C8C8C;
}

.car-item__data{
  border-radius: 15px 15px 0 0;
  background: #fff;
  position: relative;
  z-index: 1;
  padding: 0.8rem;
}
.car-item__data .name{
  font-size: 20px;
}
.car-item__data .divider{
  margin: 0 8px;
}
.car-item__dataRow.--characteristics{
  line-height: 1.5;
}
.car-item__dataInfo{
  font-size: 12px;
}
.car-item__dataInfo span{
  display: block;
  font-size: 14px;
  opacity: 0.5;
  margin-bottom: 4px;
}

@media (hover: none){
  .car-item__sliderHovers{
    display: none;
  }
}

@media (min-width: 1400px){
  .car-item__slider{
    width: 20.3%;
    /*height: 180px;*/
    height: 215px;
  }
  .car-item__trade{
    max-width: 530px;
  }
}
@media(min-width: 1200px) and (max-width: 1399px){
  .car-item__slider{
    align-self: flex-start;
    border-radius: 0 0 0 15px;
    height: 324px;
  }
}
@media(max-width: 1199px){
  .car-item__slider{
    width: 40%;
    height: 360px;
  }
}
@media(max-width: 991px){
  .car-item{
    display: block;
    flex: 0 0 100%;
  }
  .car-item__slider{
    width: 100%;
    height: auto;
    padding-top: 58.9%;
    margin-bottom: -15px;
    border-radius: 0;
  }
  .car-item__trade{
    padding-right: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .car-item__tradeForm{
    border-radius: 15px;
  }
  .car-item__tradeForm.--auto{
    border-right-color: #FC6337;
    border-bottom-color: #FC6337;
  }
}
@media(max-width: 575px){
  .car-item__tradeFormControls .button{
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .car-item__tradeFormControls{
    padding-top: 0;
  }
  .car-item__tradeFormAttention,
  .car-item__tradeFormInfo{
    margin-bottom: 2px;
    position: static;
  }
  .car-item__data .name{
    font-size: 16px;
  }
}
</style>
